import React from 'react';
import Layout from './layout/Layout';

const App = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Layout />
        </div>
    );
};

export default App;
